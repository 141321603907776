var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"column section-height"},[_vm._l((_vm.statisticData),function(subcategory,name,index){return [_c('div',{key:index,staticClass:"columns is-mobile is-marginless"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-10 has-text-centered"},[_c('div',{staticClass:"bars-category-title is-size-7"},[_vm._v(" "+_vm._s(subcategory.names[_vm.subcategoryName === '0-0' || _vm.subcategoryName === '0-1' ? '0-0' : '1-1'])+" ")]),_c('Barra',{staticClass:"is-clickable",attrs:{"postmatch":_vm.calcPercentage(subcategory, 'home'),"live":_vm.calcPercentageLive(subcategory, 'home')},nativeOn:{"click":function($event){_vm.showModal(
                    $event,
                    subcategory.homeTeam[_vm.homeTeamStatisticsType],
                    _vm.leagueAvg(subcategory),
                    subcategory.names,
                    true,
                  )}}})],1)])]),_c('div',{staticClass:"is-vertical-divider"}),_c('div',{staticClass:"column"},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-10 has-text-centered"},[_c('div',{staticClass:"bars-category-title is-size-7"},[_vm._v(" "+_vm._s(subcategory.names[_vm.subcategoryName === '0-0' || _vm.subcategoryName === '1-0' ? '0-0' : '1-1'])+" ")]),_c('Barra',{staticClass:"is-clickable",attrs:{"postmatch":_vm.calcPercentage(subcategory, 'visiting'),"live":_vm.calcPercentageLive(subcategory, 'visiting')},nativeOn:{"click":function($event){_vm.showModal(
                    $event,
                    subcategory.visitingTeam[_vm.visitingTeamStatisticsType],
                    _vm.leagueAvg(subcategory),
                    subcategory.names,
                    false,
                  )}}})],1)])])])]}),_c('ResumeTooltip',{attrs:{"data":_vm.tooltip.data,"local":_vm.tooltip.local,"names":_vm.tooltip.names,"keyName":_vm.tooltip.keyName,"leagueAvg":_vm.tooltip.leagueAvg,"left":_vm.tooltip.local,"show":_vm.tooltip.show,"x":_vm.tooltip.x,"y":_vm.tooltip.y},on:{"update:show":function($event){return _vm.$set(_vm.tooltip, "show", $event)}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }