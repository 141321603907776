<template>
  <section class="column section-height">
    <template v-for="(subcategory, name, index) in statisticData">
      <div :key="index" class="columns is-mobile is-marginless ">
        <div class="column">
          <div class="columns is-centered">
            <div class="column is-10 has-text-centered">
              <!-- '0-0' refers to possible combinations in advanced filter: 0 = accomplished and 1 = received -->
              <div class="bars-category-title is-size-7">
                {{ subcategory.names[subcategoryName === '0-0' || subcategoryName === '0-1' ? '0-0' : '1-1'] }}
              </div>
              <Barra
                class="is-clickable"
                :postmatch="calcPercentage(subcategory, 'home')"
                :live="calcPercentageLive(subcategory, 'home')"
                @click.native="
                  showModal(
                    $event,
                    subcategory.homeTeam[homeTeamStatisticsType],
                    leagueAvg(subcategory),
                    subcategory.names,
                    true,
                  )
                "
              />
            </div>
          </div>
        </div>
        <div class="is-vertical-divider"></div>
        <div class="column">
          <div class="columns is-centered">
            <div class="column is-10 has-text-centered">
              <!-- '0-0' refers to possible combinations in advanced filter: 0 = accomplished and 1 = received -->
              <div class="bars-category-title is-size-7">
                {{ subcategory.names[subcategoryName === '0-0' || subcategoryName === '1-0' ? '0-0' : '1-1'] }}
              </div>
              <Barra
                class="is-clickable"
                :postmatch="calcPercentage(subcategory, 'visiting')"
                :live="calcPercentageLive(subcategory, 'visiting')"
                @click.native="
                  showModal(
                    $event,
                    subcategory.visitingTeam[visitingTeamStatisticsType],
                    leagueAvg(subcategory),
                    subcategory.names,
                    false,
                  )
                "
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <ResumeTooltip
      :data="tooltip.data"
      :local="tooltip.local"
      :names="tooltip.names"
      :keyName="tooltip.keyName"
      :leagueAvg="tooltip.leagueAvg"
      :left="tooltip.local"
      :show.sync="tooltip.show"
      :x="tooltip.x"
      :y="tooltip.y"
    />
  </section>
</template>

<script>
import Barra from '@/components/Elements/NextMatches/Barra';
import ResumeTooltip from '@/components/Elements/NextMatches/ResumeTooltip';
import { mapGetters, mapState } from 'vuex';
import { avg } from '@/utils/league';

export default {
  name: 'Barras',
  components: {
    Barra,
    ResumeTooltip,
  },
  data() {
    return {
      tooltip: {
        show: false,
        data: {},
        names: {},
        keyName: '',
        leagueAvg: '',
        showLiveData: false,
        local: false,
        x: 0,
        y: 0,
      },
    };
  },
  computed: {
    ...mapState('nextGeneral', ['selectedAnalysis']),
    ...mapGetters('nextGeneral', [
      'statisticData',
      'showPostMatchData',
      'homeTeamStatisticsType',
      'visitingTeamStatisticsType',
      'subcategoryName',
      'accomplished',
    ]),
  },
  methods: {
    showModal(event, data, leagueAvg, names, local) {
      this.tooltip.data = data;
      this.tooltip.local = local;
      this.tooltip.names = names;
      this.tooltip.keyName = this.subcategoryName;
      this.tooltip.leagueAvg = leagueAvg;
      this.tooltip.x = event.pageX;
      this.tooltip.y = event.pageY > 700 ? event.pageY - 250 : event.pageY;
      this.tooltip.show = true;
    },
    leagueAvg(subcategory) {
      return avg(subcategory, this.accomplished);
    },
    calcPercentage(subcategory, teamType) {
      if (this.showPostMatchData) {
        const totalPostMatch =
          subcategory.homeTeam[this.homeTeamStatisticsType].totalPostGame +
          subcategory.visitingTeam[this.visitingTeamStatisticsType].totalPostGame;
        return totalPostMatch
          ? subcategory[`${teamType}Team`][this.homeTeamStatisticsType].totalPostGame / totalPostMatch
          : 0;
      }
      const leaderAvg = subcategory[`${teamType}Team`][this[`${teamType}TeamStatisticsType`]].leader.points;
      return leaderAvg
        ? subcategory[`${teamType}Team`][this[`${teamType}TeamStatisticsType`]].avgPostGame / leaderAvg
        : 0;
    },
    calcPercentageLive(subcategory, teamType) {
      const totalLive =
        subcategory.homeTeam[this.homeTeamStatisticsType].totalLive +
        subcategory.visitingTeam[this.visitingTeamStatisticsType].totalLive;
      return totalLive ? subcategory[`${teamType}Team`][this.homeTeamStatisticsType].totalLive / totalLive : 0;
    },
  },
};
</script>

<style>
.bars-category-title {
  font-family: 'Circular-Std-Medium';
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.64;
  letter-spacing: normal;
  color: #2e2e2e;
  margin-bottom: 0.4rem;
}
@media screen and (max-width: 440px) {
  .bars-category-title {
    word-spacing: 4px;
    line-height: 12px;
    padding-inline: 10px;
  }
}
</style>
