<template>
  <div class="custom-progress-bar">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 25"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      style="width: 100%"
    >
      <!-- Base -->
      <path d=" M10,10  L190,10   A1,1 0 1 1 190,15   L10,15  A1,1 0 1 1 10,10 z" fill="#e7e7e7" />
      <!-- Marcador Postmatch -->
      <path :d="`M10,10  L${p},10 A1,1 0 1 1 ${p},15  L10,15  A1,1 0 1 1 10,10 z`" :fill="colors[0]" />
      <!-- Marcador Live -->
      <polygon
        v-if="showLiveData"
        :points="`${l},10 ${l - 5},5,  ${l + 5},5`"
        :fill="colors[1]"
        :stoke="colors[1]"
        stroke-width="1"
      />
      <line
        v-if="showLiveData"
        :x1="l"
        y1="10"
        :x2="l"
        y2="15"
        :fill="colors[1]"
        :stroke="colors[1]"
        stroke-width="1"
      />
      <polygon
        v-if="showLiveData"
        :points="`${l},15 ${l - 5},20, ${l + 5},20`"
        :fill="colors[1]"
        :stoke="colors[1]"
        stroke-width="1"
      />
    </svg>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'Bars',
  props: {
    colors: {
      type: Array,
      required: false,
      default: function() {
        return ['#89e033', '#3bd8f1'];
      },
    },
    postmatch: {
      type: Number,
      required: false,
      default: 0,
    },
    live: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    ...mapState('nextGeneral', ['showLiveData']),
    p() {
      return 184 * this.postmatch + 6;
    },
    l() {
      return 180 * this.live + 10;
    },
  },
};
</script>

<style scoped>
.custom-progress-bar {
  width: 100%;
}
.custom-progress-bar:hover {
  border-radius: 5px;
  background-color: #e9f1e3;
}
</style>
